export const monthsInFrench = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const stakesList = [
  "Agriculture biologique",
  "Agriculture urbaine et aquaponie",
  "Agroforesterie",
  "Pêche durable",
  "Sylviculture durable",
  "Élevage éthique et bien-être animal",
  "Protection de la biodiversité",
  "Cultures hydroponiques et verticales",
  "Énergies renouvelables (solaire, éolien, hydroélectricité, géothermie)",
  "Bioénergies et biocarburants",
  "Efficacité énergétique et économies d'énergie",
  "Stockage d'énergie (batteries, hydrogène vert)",
  "Récupération de chaleur industrielle",
  "Solutions de refroidissement écologique",
  "Captage et stockage du carbone (CCS)",
  "Prévention et gestion de la pollution",
  "Gestion durable de l'eau",
  "Recyclage et valorisation des déchets",
  "Gestion durable des terres",
  "Conservation des océans",
  "Économie circulaire",
  "Réduction du plastique",
  "Matériaux biodégradables",
  "Gestion des déchets électroniques",
  "Bâtiments écologiques et écoquartiers",
  "Architecture bioclimatique",
  "Infrastructures vertes et durables",
  "Mobilité urbaine durable (transports publics, vélos, véhicules électriques)",
  "Solutions de transport partagées (covoiturage, auto-partage)",
  "Rénovation énergétique des bâtiments",
  "Matériaux de construction écologiques",
  "Technologies propres (cleantech)",
  "Intelligence artificielle au service de la durabilité",
  "Internet des objets (IoT) pour la gestion énergétique",
  "Blockchain pour la traçabilité durable",
  "Agritech (technologies agricoles)",
  "Bio-ingénierie et biotechnologies durables",
  "Nanotechnologies écologiques",
  "Mode éthique et commerce équitable",
  "Textiles recyclés et upcyclés",
  "Chaînes d'approvisionnement transparentes",
  "Slow fashion",
  "Fibres biologiques et durables",
  "Alimentation locale et circuits courts",
  "Produits alimentaires biosourcés",
  "Alternatives végétales et protéines végétales",
  "Réduction du gaspillage alimentaire",
  "Produits à base d'insectes",
  "Produits laitiers alternatifs (lait d'amande, avoine, etc.)",
  "Vente en vrac et zéro déchet",
  "Commerce équitable",
  "Entrepreneuriat social",
  "Intégration sociale et emploi pour les personnes en difficulté",
  "Éducation pour le développement durable",
  "Tourisme responsable et écotourisme",
  "Produits artisanaux locaux et solidaires",
  "Initiatives de santé communautaire durable",
  "Consulting en durabilité",
  "Gestion durable de la chaîne d'approvisionnement",
  "Finance verte et investissements socialement responsables (ISR)",
  "Banques éthiques et coopératives",
  "Assurance verte",
  "Éducation et sensibilisation environnementale",
  "Certifications écologiques et normes de durabilité",
  "Habitat collaboratif et coliving écologique",
  "Jardinage écologique et permaculture",
  "Produits ménagers écologiques",
  "Électroménager à faible consommation",
  "Solutions de chauffage et de climatisation durables",
  "Véhicules électriques et hybrides",
  "Transports maritimes et aériens durables",
  "Carburants alternatifs (hydrogène, biogaz)",
  "Logistique verte et optimisation des flux",
  "Infrastructures de recharge pour véhicules électriques"
]

export const biggestFrenchCities = [
  "Paris",
  "Marseille",
  "Lyon",
  "Toulouse",
  "Nice",
  "Nantes",
  "Strasbourg",
  "Montpellier",
  "Bordeaux",
  "Lille",
  "Rennes",
  "Reims",
  "Le Havre",
  "Saint-Étienne",
  "Toulon",
  "Grenoble",
  "Dijon",
  "Angers",
  "Nîmes",
  "Villeurbanne",
  "Clermont-Ferrand",
  "Saint-Denis",
  "Aix-en-Provence",
  "Le Mans",
  "Brest",
  "Tours",
  "Amiens",
  "Limoges",
  "Annecy",
  "Boulogne-Billancourt",
  "Perpignan",
  "Metz",
  "Besançon",
  "Orléans",
  "Saint-Denis de la Réunion",
  "Rouen",
  "Argenteuil",
  "Mulhouse",
  "Caen",
  "Saint-Paul",
  "Nancy",
  "Montreuil",
  "Roubaix",
  "Tourcoing",
  "Nanterre",
  "Avignon",
  "Vitry-sur-Seine",
  "Créteil",
  "Poitiers",
  "Dunkerque",
  "Asnières-sur-Seine",
  "Courbevoie",
  "Versailles",
  "Colombes",
  "Fort-de-France",
  "Aulnay-sous-Bois",
  "Rueil-Malmaison",
  "Pau",
  "Aubervilliers",
  "Le Tampon",
  "Champigny-sur-Marne",
  "Antibes",
  "Saint-Maur-des-Fossés",
  "La Rochelle",
  "Cannes",
  "Calais",
  "Saint-Nazaire",
  "Mérignac",
  "Drancy",
  "Colmar",
  "Issy-les-Moulineaux",
  "Noisy-le-Grand",
  "Évry-Courcouronnes",
  "Levallois-Perret",
  "La Seyne-sur-Mer",
  "Quimper",
  "Ajaccio",
  "Villeneuve-d'Ascq",
  "Béziers",
  "Valence",
  "Troyes",
  "Cergy",
  "Pessac",
  "Bourg-en-Bresse",
  "Ivry-sur-Seine",
  "Clichy",
  "Chambéry",
  "Les Abymes",
  "Lorient",
  "Le Blanc-Mesnil",
  "Montauban",
  "Niort",
  "Sarcelles",
  "Pantin",
  "Maisons-Alfort",
  "Meaux",
  "Villejuif",
  "Hyères",
  "Beauvais",
  "Cholet",
]